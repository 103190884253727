// import React, { useState, useEffect } from "react";
// import Card from "./CardBlog";
// import { Grid, Typography } from "@mui/material";

// const Articles = ({ currentArticleSlug }) => {
//   const [articles, setArticles] = useState([]);

//   // Fetch articles dynamically from Strapi API
//   useEffect(() => {
//     const fetchArticles = async () => {
//       try {
//         const response = await fetch(`http://localhost:1337/api/articles?populate=*` );
//         const data = await response.json();

//         // Filter out the current article
//         console.log(currentArticleSlug);
        
//         const filteredArticles = data.data.filter(article => article.attributes.slug !== currentArticleSlug);
 
        
//         // Randomly select 3 articles
//         const getRandomArticles = (array, count) => {
//           const shuffled = array.sort(() => 0.5 - Math.random());
//           return shuffled.slice(0, count);
//         };

//         const randomArticles = getRandomArticles(filteredArticles, 3);
//         setArticles(randomArticles);

//       } catch (error) {
//         console.error("Error fetching articles:", error);
//       }
//     };

//     fetchArticles();
//   }, [currentArticleSlug]);

//   return (
//     <>
//       <Typography color="secondary" fontSize={{xs:"27px",md:"30px",lg:"34px"}} marginTop="8px" marginBottom="15px">
//         Related Blogs
//       </Typography>
//       <Grid container spacing={5} sx={{ padding: { xs: "10px 0px", md: "15px 0px", lg: "20px 80px" } }}>
//         {articles.map((article, i) => (
//           <Grid
//             item
//             xs={12}
//             sm={6}
//             lg={4}
//             key={`article__left__${article.attributes.slug}`}
//             display="flex"
//             gap="15px"
//             sx={{ paddingLeft: { sm: "40px !important" } }}
//           >
//             <Card article={article.attributes} />
//           </Grid>
//         ))}
//       </Grid>
//     </>
//   );
// };

// export default Articles;

import React from "react";
import Card from "./CardBlog";
import { graphql, useStaticQuery } from "gatsby";
import { Grid, Typography } from "@mui/material";

const Articles = ({ article }) => {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle {
        nodes {
          ...ArticleCard
        }
      }
    }
  `);

  const articles = allStrapiArticle.nodes.filter(
    (node) => node.slug !== article
  );

  const getRandomArticles = (array, count) => {
    // Create a copy to avoid mutating the original array
    const shuffled = [...array].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count);
  };

  const randomArticles = getRandomArticles(articles, 3);
  console.log(randomArticles);

  return (
    <>
      <Typography
        color="secondary"
        fontSize={{ xs: "27px", md: "30px", lg: "34px" }}
        marginTop="8px"
        marginBottom="15px"
      >
        Related Blogs
      </Typography>
      <Grid
        container
        spacing={5}
        sx={{
          padding: { xs: "10px 0px", md: "15px 0px", lg: "20px 80px" },
        }}
      >
        {randomArticles.map((article) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            key={`article__left__${article?.slug}`}
            sx={{
              display: "flex",
              gap: "15px",
              paddingLeft: { sm: "40px" },
            }}
          >
            <Card article={article} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Articles;


