import React, { useState } from "react";
import Card from "./CardBlog";
import { Grid, Button, Typography, Stack } from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

const Articles = ({ articles }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  // Sort articles by latest first
  const sortedArticles = [...articles].sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
  const totalPages = Math.ceil((sortedArticles.length) / articlesPerPage);

  const handleClickNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleClickPrev = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = sortedArticles.slice(
    indexOfFirstArticle,
    Math.min(indexOfLastArticle, sortedArticles.length )
  );

  return (
    <>
      <Grid container spacing={5} sx={{ padding:{xs:"30px 10px",md:"40px 60px",lg:"50px 120px"} }}>
        {currentArticles.map((article) => (
          <Grid item xs={12} sm={6} lg={4} key={article?.id} display="flex" gap="15px" sx={{paddingLeft:{sm:"40px !important"}}}>
            <Card article={article} />
          </Grid>
        ))}
      </Grid> 

    </>
  );
};

export default Articles;
