exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-tools-js": () => import("./../../../src/pages/ai-tools.js" /* webpackChunkName: "component---src-pages-ai-tools-js" */),
  "component---src-pages-bigdata-analytics-platform-products-js": () => import("./../../../src/pages/bigdata-analytics-platform-products.js" /* webpackChunkName: "component---src-pages-bigdata-analytics-platform-products-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-cdo-handbook-7-ways-to-cut-down-data-costs-js": () => import("./../../../src/pages/cdo-handbook-7-ways-to-cut-down-data-costs.js" /* webpackChunkName: "component---src-pages-cdo-handbook-7-ways-to-cut-down-data-costs-js" */),
  "component---src-pages-cloud-transformation-js": () => import("./../../../src/pages/cloud-transformation.js" /* webpackChunkName: "component---src-pages-cloud-transformation-js" */),
  "component---src-pages-custom-software-development-js": () => import("./../../../src/pages/custom-software-development.js" /* webpackChunkName: "component---src-pages-custom-software-development-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jobs-fullstack-engineer-js": () => import("./../../../src/pages/jobs/fullstack-engineer.js" /* webpackChunkName: "component---src-pages-jobs-fullstack-engineer-js" */),
  "component---src-pages-jobs-fullstack-intern-js": () => import("./../../../src/pages/jobs/fullstack-intern.js" /* webpackChunkName: "component---src-pages-jobs-fullstack-intern-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-product-development-js": () => import("./../../../src/pages/product-development.js" /* webpackChunkName: "component---src-pages-product-development-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-case-studies-js": () => import("./../../../src/templates/case-studies.js" /* webpackChunkName: "component---src-templates-case-studies-js" */),
  "component---src-templates-job-details-js": () => import("./../../../src/templates/job-details.js" /* webpackChunkName: "component---src-templates-job-details-js" */),
  "component---src-templates-tool-details-js": () => import("./../../../src/templates/tool-details.js" /* webpackChunkName: "component---src-templates-tool-details-js" */)
}

