// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import Layout from "../components/layout/Layout";
// import Articles from "../components/articles";
// import Marticles from "../components/main_article";
// import { Stack, Typography } from "@mui/material";

// const IndexPage = () => {
//   const [articles, setArticles] = useState([]);
  
//   useEffect(() => {
//     // Fetching blog posts dynamically from Strapi
//     const fetchArticles = async () => {
//       try {
//         const response = await axios.get("http://localhost:1337/api/articles?populate=*");
//         console.log(response.data.data);        
//         setArticles(response.data.data); // Adjust based on the structure of your response
//       } catch (error) {
//         console.error("Error fetching articles: ", error);
//       }
//     };
    
//     fetchArticles();
//   }, []);

//   return (
//     <Layout>
//       <Stack padding="40px" justifyContent="center" alignItems="center" sx={{ background: "#fff" }}>
//         <Typography
//           color="#1a202c"
//           fontSize={{ xs: "34px", md: "39px", lg: "44px" }}
//           fontWeight="700"
//           marginBottom="20px"
//         >
//           Limitless Possibilities | Tech <span style={{ color: "#4c51bf" }}>@C4Scale</span>
//         </Typography>
//         <Typography
//           color="#1a202c"
//           fontSize={{ xs: "16px", md: "18px", lg: "19px" }}
//           textAlign="center"
//           width={{ xs: "90vw", md: "75vw", lg: "65vw" }}
//         >
//           In a software-defined world, the C4Scale Blog is your place to navigate through the renaissance in
//           technology. Our publication aims to provide you the full scoop on the groundbreaking research,
//           thought-leadership, and perspective on tech innovation in the world of software development, with a
//           deep context to help tech leaders make intelligent decisions.
//         </Typography>
//         {/* Dynamically display main articles and articles */}
//         <Marticles articles={articles} />
//         <Articles articles={articles} />
//       </Stack>
//     </Layout>
//   );
// };

// export default IndexPage;


import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/Layout"
import Seo from "../components/seo"
import Articles from "../components/articles"
import FArticles from "../components/farticles"
import Marticles from "../components/main_article"
import { Stack, Typography } from "@mui/material"

const IndexPage = () => {
  const { allStrapiArticle } = useStaticQuery(graphql`
    query {
      allStrapiArticle {
        nodes {
          ...ArticleCard
          insights
          featured
          product
        }
      }
    }
  `)

  // Filter articles for main card (both insights and featured are true, published is true, and product is c4scale)
  const mainArticles = allStrapiArticle.nodes.filter(
    (article) => article?.insights && article?.featured  && article?.product === "c4scale"
  )

  // Filter articles for general display (insights true but not featured, or neither true, published is true, and product is c4scale)
  const otherArticles = allStrapiArticle.nodes.filter(
    (article) => !(article?.insights && article?.featured) && article?.product === "c4scale"
  )

  return (
    <Layout>
      {/* <Seo seo={{ metaTitle: "Blog" }} /> */}
      <Stack
        padding="40px"
        justifyContent="center"
        alignItems="center"
        sx={{ background: "#fff" }}
      >
        <Typography
          color="#1a202c"
          fontSize={{ xs: "34px", md: "39px", lg: "44px" }}
          fontWeight="700"
        >
          The &nbsp;
          <span style={{ color: "#4c51bf" }}>C4Scale</span>&nbsp;
          Blog
        </Typography>
        
        {/* Pass filtered articles to respective components */}
        <FArticles articles={mainArticles} />
        <Articles articles={otherArticles} />
      </Stack>
    </Layout>
  )
}

export default IndexPage
