import React from 'react';
import Publications from '../components/Publications';
import Layout from '../components/layout/Layout';
import { Helmet } from "react-helmet"
import { Link, useStaticQuery, graphql } from "gatsby"
import ebook from "../images/ebook.webp"
import Articles from './articles';
import Card from './CardBlog';
import { Grid, Typography } from '@mui/material';

export default function Example() {
  const { allStrapiArticle } = useStaticQuery(graphql`
     query {
      allStrapiArticle {
        nodes {
          ...ArticleCard
          insights
          featured
          product
        }
      }
    }
  `)

  // Filter articles where insights is true
  const insightArticles = allStrapiArticle.nodes.filter(
    (article) => article?.insights
  )

  return (
    <>
      <div className="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="absolute inset-0">
          <div className="bg-white h-[0%]" />
        </div>
        <div className="relative max-w-7xl mx-auto">
          <div className="text-center">
            <h2 className="text-3xl tracking-tight font-semibold text-gray-900 sm:text-4xl">Blogs & Insights</h2>
          </div>
          <div className="mt-12 max-w-5xl mx-auto grid gap-5">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
              <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                <Link to="/cdo-handbook-7-ways-to-cut-down-data-costs" style={{ width: "100%" }}>
                  <a style={{ display: "flex", width: "100%" }}>

                    <img className="h-[200px] w-full object-cover" src={ebook} alt="" />

                  </a>
                </Link>
                <div className="flex-1 bg-white p-4 flex flex-col justify-between">
                  <div className="flex-1">
                    <div className="flex flex-row justify-between w-full items-center">
                      <Typography
                        fontSize={{ xs: "14px", md: "15px" }}
                        color="#1a202c"
                        fontWeight="500"
                        sx={{
                          background: "#4199e15e",
                          padding: "7px 20px",
                          width: "max-content",
                          borderRadius: "20px",
                        }}
                      >
                        E-Book
                      </Typography>

                      <Link
                        to="/cdo-handbook-7-ways-to-cut-down-data-costs"
                        className="whitespace-nowrap inline-flex items-center justify-center px-3 py-[6px] border border-transparent rounded-md shadow-sm text-[15px] font-medium text-white bg-indigo-600 hover:bg-indigo-700 hover:text-white"
                      >
                        Download
                      </Link>
                    </div>

                    <Typography fontSize={{ xs: "16px", md: "18px" }}
                      color="#1a202c"
                      fontWeight="500"
                      sx={{ mt: 1 }}>
                      <Link to="/cdo-handbook-7-ways-to-cut-down-data-costs" passHref>
                        CDO's Handbook - 7 Ways To Cut Down Data Costs</Link>
                    </Typography>
                    <Typography
                      color="#1a202c"
                      fontSize={{ xs: "14px", md: "15px" }}
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        mt: 1,
                      }}
                    >  A one-stop guide that provides a comprehensive & strategic ways to cut down data costs in your organisation from our expertise handing Data @planet scale</Typography>
                  </div>

                </div>
              </div>

              {insightArticles.map((article) => (
                <Grid item xs={12} sm={6} lg={4} key={article?.id} display="flex" gap="20px" >
                  <Card article={article} />
                </Grid>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
