import React, { useState } from "react";
import Card from "./CardBlog";
import { Grid, Button, Typography, Stack } from "@mui/material";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

const Articles = ({ articles }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  // Custom sort: 'article' category first, then by published date descending
  const sortedArticles = [...articles].sort((a, b) => {
    // First, check if either a or b has category 'article'
    if (a.category === 'Article' && b.category !== 'Article') return -1;
    if (a.category !== 'Article' && b.category === 'Article') return 1;

    // If both have the same category, sort by published date descending
    return new Date(b.publishedAt) - new Date(a.publishedAt);
  });

  const totalPages = Math.ceil(sortedArticles.length / articlesPerPage);

  const handleClickNext = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handleClickPrev = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = sortedArticles.slice(
    indexOfFirstArticle,
    Math.min(indexOfLastArticle, sortedArticles.length)
  );

  return (
    <>
      <Grid container spacing={5} sx={{ padding: { xs: "30px 10px", md: "40px 60px", lg: "60px 120px" } }}>
        {currentArticles.map((article) => (
          <Grid
            item
            xs={12}
            sm={6}
            lg={4}
            key={article?.id}
            display="flex"
            gap="15px"
            sx={{ paddingLeft: { sm: "40px !important" } }}
          >
            <Card article={article} />
          </Grid>
        ))}
      </Grid>

      <Stack gap="20px" direction="row" alignItems="center" justifyContent="center" sx={{ marginTop: '20px' }}>
        <Button
          disabled={currentPage === 1}
          onClick={handleClickPrev}
          sx={{
            backgroundColor: "#fff",
            border: "1.42399px solid #555555",
            borderRadius: "25px",
            textTransform: "capitalize",
            color: "#555555",
            padding: "6px 30px",
            fontSize: { xs: "17px", md: "20px" },
          }}
        >
          <WestIcon sx={{ marginRight: "10px", fontSize: "14px" }} />
          Previous
        </Button>
        <Typography
          fontSize={{ xs: "17px", md: "20px" }}
          component="span"
          fontWeight="700"
          textAlign={{ xs: "center", md: "left" }}
        >
          Page {currentPage} / {totalPages}
        </Typography>
        <Button
          disabled={currentPage === totalPages}
          onClick={handleClickNext}
          sx={{
            backgroundColor: "#fff",
            border: "1.42399px solid #555555",
            borderRadius: "25px",
            textTransform: "capitalize",
            color: "#555555",
            padding: "6px 30px",
            fontSize: { xs: "17px", md: "20px" },
          }}
        >
          Next
          <EastIcon sx={{ marginLeft: "10px", fontSize: "14px" }} />
        </Button>
      </Stack>
    </>
  );
};

export default Articles;
