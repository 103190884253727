import React from "react"
import ReactMarkdown from "react-markdown"
import Moment from "react-moment"
import { Link } from "gatsby"
import { graphql,useStaticQuery } from "gatsby"
import { GatsbyImage, getImage,getSrc } from "gatsby-plugin-image"
import Layout from "../components/layout/Layout"
import { Stack,Box, Typography } from "@mui/material"
import WestIcon from '@mui/icons-material/West';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Share from "../components/share";
import Related from "../components/related";
import { Helmet } from "react-helmet";

const ArticlePage = ({ data }) => {
  
  let blogUrl;
if (typeof window !== "undefined") {
  blogUrl = location.href;
} else {
  blogUrl = "https://c4scale.com/blog";
}

  const article = data.strapiArticle
  const canonicalUrl = `https://c4scale.com/blog/${article.title.replace(/\s+/g, "-").toLowerCase()}/`;
  return (
    <>
    <Helmet>
      <title>{article.title}</title>
      <meta property="og:type" content="website" />
      <meta property="og:title" content={`${article.title}`} />
      <meta name="twitter:title" content={`${article.title}`} />
      <meta name="description" content={`${article.description}`} />
      <meta property="og:description" content={`${article.description}`} />
      <meta name="twitter:description" content={`${article.description}`} />

      {/* Static image for the category */}
      <meta property="og:image" content={getSrc(article?.image?.localFile) || article.imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={getSrc(article?.image?.localFile)  || article.imageUrl} />

      {/* Canonical link */}
      <link href={canonicalUrl} rel="canonical" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta property="og:url" content={canonicalUrl} />
    </Helmet>
    
    <Layout as="article">
      <Stack marginBottom="50px">
        {article?.image?.localFile ? (
          <GatsbyImage
            image={getImage(article?.image?.localFile)}
            style={{ height: "350px", objectFit: "cover" }}
            alt="Article image"
          />
        ) : (
          <img
            src={article?.imageUrl}
            style={{ height: "350px", objectFit: "cover", width: "100%" }}
            alt="Article image"
          />
        )}
        
        <Link to="/blog"><Typography fontSize="16px" fontStyle="italic" color="primary" padding="25px" fontWeight="500"><WestIcon sx={{ marginRight: "10px", fontSize: "14px" }} /> Back to Blogs</Typography></Link>
        <Stack padding={{xs:"10px 30px",md:"10px 60px",lg:"10px 120px"}}>
          <Typography fontSize={{xs:"24px",md:"30px",lg:"36px"}} fontWeight="700" color="primary">{article.title}</Typography>
          <Stack direction="row" gap="10px" mt="12px" alignItems="center">
          <Moment format="MMM Do YYYY" style={{color:"#1a202c"}}>
                    {article?.published_at} 
          </Moment>
          
          </Stack>
          <Typography
              fontSize={{xs:"14px",md:"15px"}} 
              color="#primary"
              fontWeight="500"
              sx={{
                background: "#4199e15e",
                padding: "7px 20px",
                width: "max-content",
                borderRadius: "20px",
              }}
              textTransform="capitalize"
              mt="20px"
              mb="20px"
            >
              {article?.category}
            </Typography>
            <div
        dangerouslySetInnerHTML={{
          __html: article?.richTextBody?.data?.childMarkdownRemark?.html,
        }}
        className="article"
      />
          <Share blogUrl={blogUrl}/>
  
          {/* <Typography color="secondary" fontSize={{xs:"27px",md:"30px",lg:"34px"}}>About the author</Typography>
            <Stack padding="40px 0px" direction={{xs:"column",md:"row"}} justifyContent="space-evenly" gap="30px" alignItems={{xs:"center",sm:"flex-start"}}>
              <Stack>
            {article?.writerimage && (
              <GatsbyImage
              image={getImage(article.writerimage?.localFile)} alt="Writer's Picture" 
              style={{ borderRadius: "50%", aspectRatio: "1/1" }} 
              className="writerimage"/>
              )} 
            </Stack>
              <Stack gap="10px">
              <Typography color="primary" fontSize={{xs:"23px",md:"36px"}} fontWeight="500">
                {article.author?.name}
              </Typography>
                
                <Typography color="primary" fontSize={{xs:"14px",md:"15px"}} fontWeight="500" fontStyle="italic">
                  {article.author?.post}
                </Typography>
                <Typography color="primary" fontSize={{xs:"14px",md:"15px"}} fontWeight="400" sx={{
                display: "-webkit-box",
                WebkitLineClamp: 6,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
               
              }}>
                  {article.author?.about}
                </Typography>
              
              </Stack>
  
            </Stack> */}
            <Related article={article.slug} />
          </Stack>
        </Stack>
      </Layout>
      </>
    )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      description
      richTextBody: content {
        data {
          id
          childMarkdownRemark {
            html
          }
        }
      }
      imageUrl
      image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      author {
        name
        post
        about
      }
      category
      
    }
  }
`

export default ArticlePage
